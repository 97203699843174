import {lazy} from 'react';
import * as PATH from "./Slug";
import ROLES from "../helpers/Roles";

const Chatbot = lazy(() => import("../pages/chatbot/Chatbot"));

const UatFiles = lazy(() => import("../pages/uat/UatFiles"));
const UatFilesHistory = lazy(() => import("../pages/uat/UatFilesHistory"));
const UatDraftVersion = lazy(() => import("../pages/uat/UatDraftVersion"));
const UatReviewedVersion = lazy(() => import("../pages/uat/UatReviewedVersion"));

const ProductionFiles = lazy(() => import("../pages/production/ProductionFiles"));

const UserListView = lazy(() => import("../pages/user/UserListView"));
const UserActivityLogList = lazy(() => import("../pages/user/UserActivityLogList"));

const SettingsPage = lazy(() => import("../pages/settings/SettingsPage"));
const MonitoringPage = lazy(() => import("../pages/monitoring/MonitoringPage"));

const TelegramBotList = lazy(() => import("../pages/telegram/TelegramBotList"));
const WhatsappList = lazy(() => import("../pages/whats_app/WhatsAppList"));

const WorkflowListView = lazy(() => import("../pages/workflow/WorkflowListView"));
const CreateWorkflow = lazy(() => import("../pages/workflow/CreateWorkflow"));
const UpdateWorkflow = lazy(() => import("../pages/workflow/UpdateWorkflow"));

const WebConfig = lazy(() => import("../pages/web_config/WebConfig"));

const ProductionLiteFiles = lazy(() => import("../pages/production_lite/ProductionLiteFiles"));
const ProductionLiteHistory = lazy(() => import("../pages/production_lite/ProductionLiteHistory"));

const OnReviewUat = lazy(() => import("../pages/on_review_uat/OnReviewUat"));
const ApprovedUat = lazy(() => import("../pages/approved_uat/ApprovedUatList"));
const ApprovedUatVersions = lazy(() => import("../pages/approved_uat/ApproveUatHistoryList"));

const PageRoutes = [
    {
        path: PATH.CHAT_BOT_PATH,
        component: Chatbot,
        roles: [ROLES.EP_SUPER_USER, ROLES.OPERATOR, ROLES.LITE_EP_SUPER_USER],
    },
    {
        path: PATH.UAT_PATH,
        component: UatFiles,
        roles: [ROLES.OPERATOR],
    },
    {
        path: `${PATH.UAT_HISTORY_PATH}/:id`,
        component: UatFilesHistory,
        roles: [ROLES.OPERATOR],
    },
    {
        path: `${PATH.UAT_DRAFT_VERSION_PATH}/:id`,
        component: UatDraftVersion,
        roles: [ROLES.OPERATOR],
    },
    {
        path: `${PATH.UAT_REVIEWED_VERSION_PATH}/:id`,
        component: UatReviewedVersion,
        roles: [ROLES.OPERATOR],
    },
    {
        path: PATH.PRODUCTION_PATH,
        component: ProductionFiles,
        roles: [ROLES.MANAGER],
    },
    {
        path: PATH.USER_LIST_VIEW_PATH,
        component: UserListView,
        roles: [ROLES.EP_SUPER_USER],
    },
    {
        path: `${PATH.USER_ACTIVITY_LOG_PATH}/:id`,
        component: UserActivityLogList,
        roles: [ROLES.EP_SUPER_USER],
    },
    {
        path: PATH.SETTINGS_PATH,
        component: SettingsPage,
        roles: [ROLES.EP_SUPER_USER, ROLES.LITE_EP_SUPER_USER],
    },
    {
        path: PATH.MONITORING_PATH,
        component: MonitoringPage,
        roles: [ROLES.ALL],
    },
    {
        path: PATH.TELEGRAM_BOT_LIST_PATH,
        component: TelegramBotList,
        roles: [ROLES.OPERATOR, ROLES.LITE_EP_SUPER_USER],
    },
    {
        path: PATH.WHATSAPP_LIST_VIEW_PATH,
        component: WhatsappList,
        roles: [ROLES.OPERATOR, ROLES.LITE_EP_SUPER_USER],
    },
    {
        path: PATH.WORKFLOW_PATH,
        component: WorkflowListView,
        roles: [ROLES.OPERATOR, ROLES.LITE_EP_SUPER_USER],
    },
    {
        path: PATH.CREATE_WORKFLOW_PATH,
        component: CreateWorkflow,
        roles: [ROLES.OPERATOR, ROLES.LITE_EP_SUPER_USER],
    },
    {
        path: `${PATH.UPDATE_WORKFLOW_PATH}/:topicName`,
        component: UpdateWorkflow,
        roles: [ROLES.OPERATOR, ROLES.LITE_EP_SUPER_USER],
    },
    {
        path: PATH.WEB_CONFIG_PATH,
        component: WebConfig,
        roles: [ROLES.OPERATOR, ROLES.LITE_EP_SUPER_USER],
    },
    {
        path: PATH.PRODUCTION_LITE_PATH,
        component: ProductionLiteFiles,
        roles: [ROLES.LITE_EP_SUPER_USER],
    },
    {
        path: `${PATH.PRODUCTION_LITE_HISTORY_PATH}/:id`,
        component: ProductionLiteHistory,
        roles: [ROLES.LITE_EP_SUPER_USER],
    },
    {
        path: PATH.ON_REVIEW_UAT_PATH,
        component: OnReviewUat,
        roles: [ROLES.MANAGER],
    },
    {
        path: PATH.APPROVED_UAT_PATH,
        component: ApprovedUat,
        roles: [ROLES.MANAGER],
    },
    {
        path: `${PATH.APPROVED_UAT_HISTORY_PATH}/:id`,
        component: ApprovedUatVersions,
        roles: [ROLES.MANAGER],
    },
]

export default PageRoutes;
