import {getCurrentDateLong, getSubtractedDateLong, getSubtractedMonthLong} from "./Utils";
import {PRIMARY_COLOR, RED_COLOR, REVIEW_COLOR} from "./ChatbotConstant";

export const ACCESS_TOKEN = "enterprise-chatbot-token";
export const TEMP_USER = "chatbot-temp-user"
export const EP_PROFILE = "ep-profile";

export const DATE_FORMAT = "YYYY-MM-DD";
export const DATE_FORMAT_TWO = "MMM DD";
export const DATE_FORMAT_THREE = "YYYY/MM/DD";
export const TIME_FORMAT = "hh:mm:ss A";
export const TIME_FORMAT_TWO = "hh:mm A";

export const USER_FOLDER = "USER";

export const QUESTION_NODE_TYPE = "QUESTION";
export const WEBHOOK_NODE_TYPE = "WEBHOOK";
export const REPLY_TEXT_NODE_TYPE = "REPLY_TEXT";
export const DEV_WEBHOOK_NODE_TYPE = "DEV_WEBHOOK";
export const CONDITION_NODE_TYPE = "CONDITION";
export const NOTE_NODE_TYPE = "NOTE";
export const NOTE_EDGE_NODE_TYPE = "NOTE_EDGE";

export const WORKFLOW_JSON = "workflow-json";

export const LITE_MODE = "LITE";
export const PRO_MODE = "PRO";

export const iconByNodeType = {
    "QUESTION": "/assets/images/workflow_question.svg",
    "REPLY_TEXT": "/assets/images/workflow_reply_icon.svg",
    "DEV_WEBHOOK": "/assets/images/workflow_webhook_icon.svg",
    "CONDITION": "/assets/images/workflow_condition.svg",
}

export const operatorValues = [
    {
        id: 1,
        label: "is",
        value: "is"
    },
    {
        id: 1,
        label: "is not",
        value: "is not"
    }
]

export const blockColors = [
    {
        id: 1,
        name: "Green",
        colorCode: "#CFE9D4",
    }, {
        id: 2,
        name: "Cyan",
        colorCode: "#DDE4EA",
    }, {
        id: 3,
        name: "Blue",
        colorCode: "#D3E5F4",
    }, {
        id: 4,
        name: "Purple",
        colorCode: "#E3E0F1",
    }, {
        id: 5,
        name: "Orange",
        colorCode: "#F5E0DC",
    }, {
        id: 6,
        name: "Pink",
        colorCode: "#FCD4DE",
    },
]

export const MONITOR_API_USAGE_GRAPH_DATE_MAP = {

    "SEVEN_DAYS": {
        dateFrom: getSubtractedDateLong(7),
        dateTo: getCurrentDateLong(),
    },
    "THIRTY_DAYS": {
        dateFrom: getSubtractedDateLong(30),
        dateTo: getCurrentDateLong(),
    },
    "THREE_MONTHS": {
        dateFrom: getSubtractedMonthLong(3),
        dateTo: getCurrentDateLong(),
    },
    "SIX_MONTHS": {
        dateFrom: getSubtractedMonthLong(6),
        dateTo: getCurrentDateLong(),
    },
    "TWELVE_MONTHS": {
        dateFrom: getSubtractedMonthLong(12),
        dateTo: getCurrentDateLong(),
    },
}

export const UAT_VERSION_STATUS_MAP_COLOR = {
    "ACCEPTED": PRIMARY_COLOR,
    "REJECTED": RED_COLOR,
    "DRAFT": "",
    "ON_REVIEW": REVIEW_COLOR
}